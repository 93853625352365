import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { createStyles, StyleRules } from '@material-ui/styles';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';

import { confirmVerificationCode, newPassword, resetPassword } from '../../actions/userActions';
import { AppState } from '../../reducers';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { FUserTypeEnum } from '../../reducers/userReducer';
import { EMAIL_VALIDATION_REGEX /*PHONE_VALIDATION_REGEX*/ } from '../../utils/constant';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
    },
    mainContentsTitle: {
      color: '#2e2e2e',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      margin: 0,
      paddingTop: 0,
    },
    input: {
      margin: '14',
      flex: 1,
      marginBottom: '0',
    },
    buttonGroup: {
      width: '50%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    textSuccess: {
      fontSize: 10,
      color: 'green',
    },
    textError: {
      fontSize: 10,
      color: 'red',
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.contrastText,
    },
    slogan: {
      marginTop: theme.spacing(1),
    },
    img: {
      width: 32,
    },
    formContainer: {
      margin: 'auto',
      width: 396,
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(0.5, 0, 0.5),
    },
    formFooter: {
      margin: 'auto',
      marginTop: 16,
    },
    circularProgress: {
      display: 'flex',
    },
    formControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      // minWidth: 120,
    },
    link: {
      color: 'black',
      textDecoration: 'none',
    },
  });

interface VerificationCode {
  code?: string;
}

interface ResetPasswordState {
  email?: string;
}

interface NewPassword {
  password?: string;
}

enum Steps {
  sendEmail,
  verificationCode,
  changePassword,
}

const ResetPassword = ({
  classes,
  inputEmail,
  code,
  newPasswordChange,
}: // userType,
Props): React.ReactElement => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const user = useSelector((appState: AppState) => appState.user);
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [emailText, setEmailText] = useState<string>((inputEmail as string) || '');
  const [emailHelperText, setEmailHelperText] = React.useState<string>();
  const [buttonDisable, setButtonDisable] = React.useState<boolean>(true);
  const [step, setStep] = useState<Steps>(Steps.sendEmail);
  const [verificationCodeText, setVerificationCodeText] = useState<string>((code as string) || '');
  const [verificationButton, setVerificationButton] = useState<boolean>(false);
  const [password, setPassword] = useState<string>((newPasswordChange as string) || '');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = React.useState<string>();
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [changePasswordButton, setChangePasswordButton] = useState<boolean>(true);
  const [confirmPasswordDisabled, setConfirmPasswordDisabled] = useState<boolean>(true);

  const validateEmail = (): boolean => {
    setEmailHelperText('');
    setEmailError(false);
    if (!EMAIL_VALIDATION_REGEX.test(emailText)) {
      setEmailHelperText('邮箱地址格式不正确');
      setEmailError(true);
      return true;
    }
    return false;
  };

  const handleChange = (prop: keyof ResetPasswordState) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const targetValue = event.target.value;
    // prop === 'phone' && setPhoneText(targetValue);
    prop === 'email' && setEmailText(targetValue);
  };

  const handleChangeVerification = (prop: keyof VerificationCode) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const targetValue = event.target.value;
    prop === 'code' && setVerificationCodeText(targetValue);
  };

  const handleChangePassword = (prop: keyof NewPassword) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const targetValue = event.target.value;
    prop === 'password' && setPassword(targetValue);
  };

  const handleChangeConfirmPassword = (prop: keyof NewPassword) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const targetValue = event.target.value;
    prop === 'password' && setConfirmPassword(targetValue);
  };

  const handleSubmitCode = async (): Promise<void> => {
    const code = await dispatch(confirmVerificationCode(emailText, verificationCodeText));
    if (code === false) {
      setStep(Steps.changePassword);
    }
  };

  const handleResetEmail = async (): Promise<void> => {
    if (validateEmail() == false) {
      const result = await dispatch(resetPassword(emailText));
      if (result === false) {
        setStep(Steps.verificationCode);
      }
    }
    return;
  };

  const handleSubmitNewPassword = async (): Promise<void> => {
    const code = await dispatch(newPassword(emailText, password, verificationCodeText));
    if (code === false) {
      dispatch(showNotificationAction({ severity: 'success', message: '修改新密码成功' }));
      await new Promise(f => setTimeout(f, 3000));
      navigate('/consultant/login');
    }
  };

  const sendEmailForm = (
    <form className={classes.form} noValidate>
      <h2>忘记密码？</h2>
      <span>简单两步，即可重设密码</span>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="邮箱地址"
        error={emailError}
        helperText={emailHelperText}
        name="username"
        value={emailText}
        onChange={handleChange('email')}
        onBlur={validateEmail}
        autoFocus={false}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleResetEmail}
        disabled={buttonDisable}
      >
        重设密码
      </Button>
      <Link to={`/${user.userType?.toLocaleLowerCase()}/login`} className={classes.link}>
        <Button fullWidth variant="contained" color="primary" className={classes.submit}>
          返回
        </Button>
      </Link>
      <Grid container>
        <Grid item className={classes.formFooter}>
          <span>没有账号？</span>
          <Link to="/consultant/register" color="secondary">
            <Button variant="contained" color="primary" size="small">
              注册新用户
            </Button>
          </Link>
        </Grid>
      </Grid>
    </form>
  );
  const emailVerifyingView = (
    <>
      <h2>查看你的邮件</h2>
      <Typography variant="subtitle1" align="center">
        您将在此处收到验证码，以便您重置帐户密码。
      </Typography>
      <Typography variant="h6" align="center" color="secondary">
        <b>{emailText}</b>
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="verification"
          label="验证码"
          name="verification"
          value={verificationCodeText}
          onChange={handleChangeVerification('code')}
        />
        <Button
          style={{ marginTop: '16px' }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={verificationButton}
          onClick={handleSubmitCode}
        >
          验证
        </Button>
      </form>
    </>
  );

  const passwordChangeView = (
    <>
      <h2>重置您的密码</h2>
      <span>输入长度至少 8 -16个字符密码</span>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="新密码"
          type="password"
          id="password"
          value={password}
          onChange={handleChangePassword('password')}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="确认密码"
          error={passwordError}
          helperText={passwordHelperText}
          name="ConfirmPassword"
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          autoFocus={false}
          disabled={confirmPasswordDisabled}
          onChange={handleChangeConfirmPassword('password')}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={changePasswordButton}
          onClick={handleSubmitNewPassword}
        >
          修改
        </Button>
      </form>
    </>
  );
  useEffect(() => {
    // Check if user typed something, if not: disable the button
    if (emailText === '') {
      setButtonDisable(true);
    }
    // if yes: enable the button if the email is valid
    else {
      if (validateEmail() == true) {
        setButtonDisable(true);
      } else {
        setButtonDisable(false);
      }
    }
    if (verificationCodeText === '') {
      setVerificationButton(true);
    } else {
      setVerificationButton(false);
    }
    if (password === '') {
      setConfirmPasswordDisabled(true);
      setConfirmPassword('');
      setPasswordHelperText('');
      setPasswordError(false);
    } else {
      setConfirmPasswordDisabled(false);
      setPasswordHelperText('');
      setPasswordError(false);
    }
    if (password && confirmPassword != '') {
      if (password === confirmPassword) {
        // const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/;

        const reg = /^.{8,16}$/;

        setPasswordHelperText('');
        setPasswordError(false);
        if (!reg.test(confirmPassword)) {
          setPasswordHelperText('密码格式不正确，请输入8-16位字符');
          setPasswordError(true);
        } else {
          setChangePasswordButton(false);
          setPasswordHelperText('');
          setPasswordError(false);
        }
      } else {
        setChangePasswordButton(true);
        setPasswordHelperText('两次输入的密码不一致');
        setPasswordError(true);
      }
    }
  });
  if (isLoading && (step === Steps.sendEmail || step === Steps.verificationCode)) {
    return <Skeleton variant="rect" width="100%" height={218} animation="wave" />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.formContainer}>
        {!isLoading && (
          <>
            {step === Steps.sendEmail && sendEmailForm}
            {step === Steps.verificationCode && emailVerifyingView}
            {step === Steps.changePassword && passwordChangeView}
          </>
        )}
      </Paper>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  inputEmail?: string | undefined;
  code?: string | undefined;
  newPasswordChange?: string | undefined;
  userType?: FUserTypeEnum;
}

export default withStyles(styles)(ResetPassword);
