import React from 'react';

import LoginLayout from '../layout/LoginLayout';
import debug from '../utils/debug';
import ResetPassword from '../views/resetPassword/Index';

const LoginPage = (): React.ReactElement => {
  debug('Login page is loaded');
  return (
    <LoginLayout>
      <ResetPassword />
    </LoginLayout>
  );
};

export default LoginPage;
